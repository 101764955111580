import { useCallback } from "react";

export function disableClick(e: React.MouseEvent) {
  e.preventDefault();
}

export default function useDisableLinkHandler() {
  return useCallback((e: React.MouseEvent) => {
    e.preventDefault();
  }, []);
}
