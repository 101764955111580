import * as React from "react";

const SvgVisiteWebsite = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    height={512}
    viewBox="0 0 384 384"
    width={512}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M374 0h-99.7c-5.523 0-10 4.477-10 10s4.477 10 10 10h75.712L155.5 216.375a10.002 10.002 0 00.227 13.973c3.855 3.824 10.058 3.867 13.972.105L364 34.301v75.398c0 5.524 4.477 10 10 10s10-4.476 10-10V10c0-5.523-4.477-10-10-10zm0 0" />
    <path d="M324.137 214.492c-5.524 0-10 4.477-10 10V334c-.02 16.563-13.438 29.98-30 30H50c-16.562-.02-29.98-13.438-30-30V99.863c.02-16.562 13.438-29.98 30-30h109.508c5.523 0 10-4.476 10-10 0-5.523-4.477-10-10-10H50c-27.602.032-49.969 22.399-50 50V334c.031 27.602 22.398 49.969 50 50h234.137c27.601-.031 49.968-22.398 50-50V224.492c0-5.523-4.477-10-10-10zm0 0" />
  </svg>
);

export default SvgVisiteWebsite;
