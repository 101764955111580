const symbols: string[] = ['.', ',']

export default function clearMetaText(text: string) {
  let clearedText = text

  symbols.forEach(s => {
    clearedText = clearedText.replaceAll(s, "")
  })

  return clearedText
}