let originalStyles = document.body.style.cssText;
let scrollTop = getScrollTop();

export function fixBody() {
  scrollTop = getScrollTop();
  originalStyles = document.body.style.cssText;
  document.body.style.cssText = `; overflow: hidden; position: fixed; height: 100%; width: 100%; top: ${-scrollTop}px;`;
}

export function unfixBody() {
  document.body.style.cssText = originalStyles;
  getScrollContainer().scrollTop = scrollTop;
}

export function getScrollContainer() {
  return document.scrollingElement || document.documentElement;
}

export function getScrollTop() {
  return getScrollContainer().scrollTop;
}
