import { artworkRouteConfig } from "features/Artwork/artwork.routes";
import { sectionRoute } from "features/Sections/section.routes";
import { viewingRoomRoutes } from "features/ViewingRoom/viewing-room.routes";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { parse } from "utils/query-string";

export default function useMoveTo() {
  const {
    location: { search },
    replace,
  } = useHistory();

  const query = parse(search);

  useEffect(() => {
    const moveTo: string | undefined = query.moveTo;
    const vrId: string | undefined = query.vrId;
    const artId: string | undefined = query.artId;
    const sectionId: string | undefined = query.sectionId;

    if (moveTo === "vr" && vrId) {
      replace(viewingRoomRoutes.link(vrId));
    } else if (moveTo === "art" && vrId && artId) {
      replace(artworkRouteConfig.link(vrId, artId));
    } else if (moveTo === "section" && sectionId) {
      replace(sectionRoute.link(sectionId));
    }
  }, [query.artId, query.moveTo, query.sectionId, query.vrId, replace]);
}
