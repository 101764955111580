import { useEffect, useState } from "react";
import { fixBody, unfixBody } from "utils/fixedBodyHeight";

let bodyFixedId: number | undefined;

export default function useFixBody(fix: boolean) {
  const [selfFixId, setSelfFixId] = useState<number>();
  useEffect(() => {
    if (fix && !bodyFixedId) {
      fixBody();
      bodyFixedId = new Date().getTime();
      setSelfFixId(bodyFixedId);
    } else if (bodyFixedId === selfFixId && !fix) {
      unfixBody();
      bodyFixedId = undefined;
      setSelfFixId(bodyFixedId);
    }
    return () => {
      if (fix && bodyFixedId === selfFixId) {
        unfixBody();
        bodyFixedId = undefined;
        setSelfFixId(bodyFixedId);
      }
    };
  }, [fix, selfFixId]);
}
