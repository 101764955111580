import * as React from "react";

const SvgBurgerMenuDesktop = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    className="outlined"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25.34 17.19"
    {...props}
  >
    <path
      d="M.5.5h24.34M.5 16.69h24.34M.5 8.6h24.34"
      fill="#000"
      stroke="#000"
      strokeLinecap="square"
    />
  </svg>
);

export default SvgBurgerMenuDesktop;
