import * as React from "react";

const SvgHeartOutline = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    className="filled"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    height={19}
    viewBox="0 0 22 19"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M11.409 2.295C12.542.831 14.215-.01 15.974 0c3.303 0 5.98 2.914 5.98 6.508 0 6.245-9.878 12.172-10.278 12.404a.448.448 0 01-.534 0C10.742 18.68.863 12.666.863 6.508.863 2.914 3.541 0 6.843 0c1.762-.022 3.44.821 4.566 2.295zM1.93 6.508c0 4.996 7.93 10.254 9.478 11.242 1.548-.959 9.478-6.188 9.478-11.242.002-1.808-.834-3.494-2.224-4.483-2.271-1.615-5.316-.922-6.8 1.549a.587.587 0 01-.125.135.532.532 0 01-.783-.135c-.895-1.524-2.45-2.435-4.111-2.412-2.713 0-4.913 2.394-4.913 5.346z"
    />
  </svg>
);

export default SvgHeartOutline;
