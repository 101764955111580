import clsx from "clsx";

export default function mergeClasses<T, K>(classes1: T, classes2?: K) {
  const result = { ...classes1 } as T & K;

  Object.entries(classes2 ?? {}).forEach(([key, value]) => {
    (result as any)[key] = clsx((result as any)[key], value as any);
  });
  return result;
}
