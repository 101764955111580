import useSelfInfo from "features/Profile/hooks/useSelfInfo";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setAccessTime } from "../store/authSlice";
import usePreviewSession from "./usePreviewSession";

const endDateFallback = new Date(2100).toISOString();

export default function useObserveAccess() {
  const { isPreview } = usePreviewSession();
  const { data } = useSelfInfo({
    enabled: !isPreview,
  });
  const dispatch = useDispatch();

  let enterDate: string | null = null;
  if (data?.currentFairAccessDate === undefined) {
    enterDate = data?.AccessLevel.accessDate || null;
  } else {
    enterDate = data?.currentFairAccessDate;
  }
  const endDate = data?.currentFairEndDate ?? endDateFallback;

  useEffect(() => {
    if (data) {
      dispatch(setAccessTime(enterDate, endDate));
    }
  }, [data, dispatch, endDate, enterDate]);
}
