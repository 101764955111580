import * as React from "react";

const SvgExport = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    className="filled"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 20"
    {...props}
  >
    <path
      d="M14.438 6h-1.82v1.273h1.213v11.454H1.699V7.273h1.213V6h-1.82c-.335 0-.607.285-.607.636v12.728c0 .351.272.636.607.636h13.346c.335 0 .606-.285.606-.636V6.636c0-.351-.271-.636-.606-.636z"
    />
    <path
      d="M6.7 2.34V11h1.158V2.34l2.485 2.623.819-.864L7.279 0 3.397 4.099l.818.864L6.7 2.339z"
    />
  </svg>
);

export default SvgExport;
