import useMobileMenu from "features/MobileMenu/hooks/useMobileMenu";
import { meRoute } from "features/Profile/profile.routes";
import useShallowSearch from "features/Search/hooks/useShallowSearch";
import React from "react";
import { useRouteMatch } from "react-router-dom";
import { HeaderActionProps } from "../header.interfaces";
import ProfileButton from "./ProfileButton";

const ProfileAction = (props: HeaderActionProps) => {
  const match = useRouteMatch(meRoute.path);
  const { closeSearch } = useShallowSearch();
  const { close } = useMobileMenu();
  return (
    <ProfileButton
      onClick={() => {
        closeSearch();
        close();
      }}
      active={!!match}
      {...props}
    />
  );
};

export default ProfileAction;
