import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "store/rootReducer";

export default function useSelfAccess() {
  const { allowAccess, accessType } = useSelector(
    (root: RootState) => ({
      allowAccess: root.auth.accessType === "started",
      accessType: root.auth.accessType,
      accessTime: root.auth.enterTime,
    }),
    shallowEqual
  );

  return {
    allowAccess: allowAccess,
    accessType,
  };
}
