import { styled } from "@linaria/react";
import clsx from "clsx";
import Button from "components/Button/Button";
import React from "react";
import { Field, useForm, useFormState } from "react-final-form";
import useFiltersMenu, { FilterePanelKeys } from "../hooks/useFiltersMenu";
import SearchBaseMenu from "../SearchBaseMenu/SearchBaseMenu";
import {
  FilterCheckbox,
  FilterCheckboxLabel,
} from "./components/FilterCheckbox";

export interface SearchFiltersProps extends React.HTMLAttributes<HTMLElement> {
  name: FilterePanelKeys;
}

const Buttons = styled.div`
  display: flex;
  padding-top: 30px;
`;

const StyledButton = styled(Button)`
  min-height: 35px;
  letter-spacing: 0.67px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  &:hover {
    background: transparent;
    color: #000;
  }
  .dark & {
    border: 1px solid white;
    background-color: transparent;
  }

  & + & {
    margin-left: 15px;
  }
`;

const StyledCheckboxLabel = styled(FilterCheckboxLabel)`
  font-size: 14px;
  & + & {
    margin-top: 19px;
  }
`;

const SearchFiltersMenu = React.forwardRef<HTMLDivElement, SearchFiltersProps>(
  ({ className, name, ...rest }, ref) => {
    const { change } = useForm();
    const { dirty } = useFormState();
    const { filtersMenuItems } = useFiltersMenu();

    return (
      <SearchBaseMenu className={className} ref={ref} {...rest}>
        {filtersMenuItems[name]?.map((item) => (
          <StyledCheckboxLabel key={item.key}>
            <Field
              type={item.type}
              name={name}
              value={item.key}
              component={FilterCheckbox}
              variant="filled"
            />
            {item.label}
          </StyledCheckboxLabel>
        ))}
        <Buttons>
          <StyledButton
            className={clsx("fullWidth", "small")}
            onClick={() => change(name, [])}
            type="button"
          >
            Clear All
          </StyledButton>
          <StyledButton
            className={clsx("fullWidth", "small", {
              pressed: dirty,
            })}
            type="submit"
          >
            Apply
          </StyledButton>
        </Buttons>
      </SearchBaseMenu>
    );
  }
);

export default SearchFiltersMenu;
