import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import clsx from "clsx";
import { fadeClass } from "components/Animations/Fade";
import { spacerMixin } from "components/BaseSpacer";
import { NestedMenuHOC, useNestedMenu } from "components/NestedMenu/NestedMenu";
import { FixedSubheader } from "features/Header/SubheaderBase/SubheaderBase";
import useFiltersMenu from "features/Search/hooks/useFiltersMenu";
import useSearch from "features/Search/hooks/useSearch";
import useShallowSearch from "features/Search/hooks/useShallowSearch";
import { SearchFilters } from "features/Search/interfaces/search.interfaces";
import FilterMenuButton from "features/Search/SearchFilters/components/FilterMenuButton";
import ClickOutsideHander from "features/Search/SearchFilters/components/FiltersPopupWrapper";
import SearchFiltersMenu from "features/Search/SearchFilters/SearchFiltersMenu";
import React, { useCallback } from "react";
import { Form } from "react-final-form";
import { CSSTransition } from "react-transition-group";
import { customProps } from "styles";
import { up } from "styles/utils/breakpoints";

const textCss = css`
  font-size: 14px;
  line-height: 1.67;

  letter-spacing: 0.266667px;
  /* text-transform: uppercase; */

  ${up('xl')} {
    font-size: 16px;
  }

  &.active {
    font-weight: 500;
  }
`;

const filterButtonCss = css`
  cursor: pointer;
`;

const flexStretch = css`
  display: flex;
  align-items: stretch;
`;

const listItem = css`
  & + & {
    margin-left: 35px;
  }
`;

const StyledForm = styled.form`
  display: flex;
  align-self: stretch;

  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  transform: translate(-50%, 0);
`;

const FilterWrapper = styled.div`
  position: absolute;
  top: calc(100%);
  border-top: 1px solid #000;
  left: 50%;
  transform: translate(-50%, 0);
  transition: opacity 100ms ease-in-out;

  max-height: calc(
    (var(--vh, 1vh) * 100) - var(${customProps.headerHeight}) -
      var(${customProps.subHeaderHeight})
  );
  overflow-y: auto;
`;

const FilterButtonWrapper = styled.div`
  position: relative;
`;

const Root = styled(FixedSubheader)`
  justify-content: flex-start;
  ${spacerMixin};

  h2 {
    text-transform: initial;
  }
`;

const ViewAllButton = styled.button`
  cursor: pointer;
  &:hover {
    font-weight: 500;
  }
`

const HeaderSearchFilters = () => {
  const { filtersMenuItemsArray } = useFiltersMenu();
  const { expanded, closeAll } = useNestedMenu();
  const { deepSearch, handleClearSearch, hideSearch } = useShallowSearch();
  const { handleResetFilters } = useSearch();

  const handleSubmit = useCallback(
    (values: Partial<SearchFilters>) => {
      handleResetFilters();
      deepSearch(values);
      closeAll();
    },
    [closeAll, deepSearch, handleResetFilters]
  );


  const handleViewAll = useCallback(() => {
    handleClearSearch();
    handleResetFilters();
    setTimeout(() => deepSearch({ name: "" }));
    hideSearch();
  }, [deepSearch, handleClearSearch, handleResetFilters, hideSearch]);

  return (
    <Root>
      <h2 className={textCss}>Filter by</h2>
      <Form onSubmit={handleSubmit}>
        {({ handleSubmit }) => (
          <StyledForm onSubmit={handleSubmit}>
            {filtersMenuItemsArray
              .filter((item) => item.filter !== "sortBy")
              .map((item) => (
                <FilterButtonWrapper className={clsx(flexStretch, listItem)}>
                  <ClickOutsideHander
                    className={flexStretch}
                    key={item.filter}
                    name={item.filter}
                  >
                    <FilterMenuButton
                      className={clsx(textCss, filterButtonCss)}
                      name={item.filter}
                    >
                      {item.label}
                    </FilterMenuButton>
                    <CSSTransition
                      mountOnEnter
                      unmountOnExit
                      in={expanded[item.filter]}
                      timeout={100}
                      classNames={fadeClass}
                    >
                      <FilterWrapper>
                        <SearchFiltersMenu name={item.filter} />
                      </FilterWrapper>
                    </CSSTransition>
                  </ClickOutsideHander>
                </FilterButtonWrapper>
              ))}
            <ViewAllButton onClick={handleViewAll} className={clsx(listItem, textCss)}>
              View All
            </ViewAllButton>
          </StyledForm>
        )}
      </Form>
    </Root>
  );
};

export default NestedMenuHOC({ singleExpanded: true })(HeaderSearchFilters);
