import { styled } from "@linaria/react";
import { homeRouteConfig } from "features/Home/home.routes";
import useMobileMenu from "features/MobileMenu/hooks/useMobileMenu";
import MobileNavigationMenu from "features/MobileMenu/MobileNavigationMenu";
import MobileMainNavigation from "features/Navigation/MobileMainNavigation";
import React, { PropsWithChildren, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { zIndex } from "styles";
import MenuAction from "./components/MenuAction";
import SearchAction from "./components/SearchActionMobile";
import SearchOverlayMobile from "./components/SearchOverlayMobile";
import logo from "images/logo-expo.png";
import SvgNorthernTrust from "components/icons/NorthernTrust";
import Spacer from "components/Spacer";
import usePreviewSession from "features/Auth/hooks/usePreviewSession";

const HeaderWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: ${zIndex.header};

  background-color: #fff;
`;

const HeaderContainer = styled.header`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 13px;

  border-top: 1px solid black;
  border-bottom: 1px solid white;

  min-height: 101px;
  height: var(--header-height, 101px);
`;

const HeaderPlaceholder = styled.div`
  min-height: 101px;
  height: var(--header-height, 101px);
`;

const LogoContainer = styled.div`
  /* height: 45px; */
  height: 37px;
  margin-right: 10px;
  & img {
    height: 100%;
  }
`;

const Sponsor = styled("div")`
  display: flex;
  flex-shrink: 1;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

type MobileHeaderProps = PropsWithChildren<{
  className?: string;
  Navigation?: React.ComponentType;
  RightAction?: React.ComponentType<any>;
  LeftAction?: React.ComponentType<any>;
}>;

const MobileHeader = ({
  className,
  Navigation = MobileMainNavigation,
  RightAction = SearchAction,
  LeftAction = MenuAction,
}: MobileHeaderProps) => {
  const { opened, close } = useMobileMenu();

  const { pathname } = useLocation();
  useEffect(() => {
    close();
  }, [close, pathname]);

  const { isPreview } = usePreviewSession();

  return (
    <>
      <HeaderWrapper className={className}>
        <HeaderContainer id="header-navigation">
          <Container>
            <div style={{ display: "flex" }}>
              <Link to={homeRouteConfig.link}>
                <LogoContainer>
                  <img src={logo} alt="" />
                </LogoContainer>
              </Link>
              <Sponsor>
                <SvgNorthernTrust color="#000" height={36} />
              </Sponsor>
            </div>
            <Spacer>{!isPreview && <LeftAction className="menu" />}</Spacer>
          </Container>
          <MobileNavigationMenu isOpen={opened} onRequestClose={close}>
            <SearchOverlayMobile/>
            <Navigation />
          </MobileNavigationMenu>
        </HeaderContainer>
      </HeaderWrapper>
      <HeaderPlaceholder className="placeholder" />
    </>
  );
};

export default MobileHeader;
