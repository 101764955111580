import { useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { variables } from "styles";
import { setBreakpoint } from "../store/layoutUtilsSlice";

export default function useLayoutObserver() {
  const dispatch = useDispatch();

  const l = useMediaQuery({ minWidth: variables.lMinWidth });

  const m = useMediaQuery({
    minWidth: variables.mMinWidth,
    maxWidth: variables.mMaxWidth,
  });

  const s = useMediaQuery({
    query: `(max-width: ${variables.sMaxWidth}) and (min-width: ${variables.sMinWidth}) and (orientation: portrait), (max-width: ${variables.sMaxWidth}) and (min-width: ${variables.sMinWidth}) and (min-height:${variables.sMinHeight}) and (orientation: landscape)`,
  });
  const xs = useMediaQuery({
    query: `(orientation: portrait) and (max-width: ${variables.xsMaxWidth}), (orientation: landscape) and (max-width: ${variables.xsMaxWidth}) and (max-height: ${variables.xsMaxHeight})`,
  });

  useLayoutEffect(() => {
    if (xs) {
      dispatch(setBreakpoint({ breakpoint: "xs" }));
    } else if (s) {
      dispatch(setBreakpoint({ breakpoint: "s" }));
    } else if (m) {
      dispatch(setBreakpoint({ breakpoint: "m" }));
    } else if (l) {
      dispatch(setBreakpoint({ breakpoint: "l" }));
    }
  }, [dispatch, l, m, s, xs]);
}
