export const publicRoute = {
  path: "/",
  link: "/",
};

export const welcomeRoute = {
  path: "/welcome",
  link: () => "/welcome",
};

export const requestRestoreLink = {
  path: '/request-restore',
  link: () => '/request-restore'
}

export const restorePasswordRoute = {
  path: '/user/restore/:token',
  link: () => '/user/restore/'
}

export const loginRoute = {
  path: "/login",
  link: () => "/login",
};

export const registrationRoute = {
  path: "/registration",
  link: () => "/registration",
};

export const devSignInRoute = {
  path: "/developer-login",
  link: "/developer-login",
};

export const previewRoutes = {
  path: "/preview",
  link: "/preview",
};
