import { fairRoute } from "features/FairPage/fair.routes";
import useConstants from "features/ServerConstants/hooks/useConstants";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { up } from "styles/utils/breakpoints";
import useBreakpoint from "styles/utils/useBreakpoint";
import { ContextNavigationDesktop } from "../ContextNavigation/ContextNavigationDesktop";
import { ContextNavigationMobile } from "../ContextNavigation/ContextNavigationMobile";

type Props = {
  className?: string;
};
const FairContextNavigation = ({ className }: Props) => {
  const { fairId } = useParams<{ fairId: string }>();

  const { data: constants } = useConstants()

  // Current Fair
  const currentFair = useMemo(() => {
    const fair: any = constants?.maps?.fair

    return fair ? fair[fairId] : undefined;
  }, [constants, fairId]);

  const fairTitle = useMemo(() => {
    return currentFair?.name || ""
  }, [currentFair]);

  // Navs
  const [prevLink, nextLink] = useMemo(() => {
    const fairsIds = constants?.Fair.map(({ id }) => id) || []

    const currentFairIndex = fairsIds.findIndex((id) => id === parseInt(fairId));

    const prevId = fairsIds[currentFairIndex - 1];
    const nextId = fairsIds[currentFairIndex + 1];

    const prev = prevId ? fairRoute.link(prevId) : "";
    const next = nextId ? fairRoute.link(nextId) : "";

    return [prev, next];
  }, [fairId, constants?.Fair]);
  
  const upLg = useBreakpoint(up("lg"));

  return (
    <>
    { upLg && (
      <ContextNavigationDesktop
        className={className}
        prevLink={prevLink}
        nextLink={nextLink}
        meta={[
          { name: 'Home', link: () => '/' },
          { name: fairTitle, link: fairRoute.link(fairId) }
        ]}
        type="fair"
      />
    )}
    
    { !upLg &&(
      <ContextNavigationMobile
        className={className}
        prevLink={prevLink}
        nextLink={nextLink}
        title={'Fairs'}
        type="section"
      />
    )}
    </>
  );
};

export default FairContextNavigation;
