import useUpdateQueryState from "features/Navigation/hooks/useUpdateQueryState";
import { PaginationInfo } from "features/Pagination/interfaces/pagination.interfaces";
import { useCallback } from "react";
import { SearchFilters } from "../interfaces/search.interfaces";
import { getPaginationInfo, getSearchState } from "./useSearchState";

export default function useSearch() {
  const { push, replace } = useUpdateQueryState();

  const handleFiltersChanged = useCallback(
    (filter: Partial<SearchFilters>) => {
      replace({
        ...filter,
        skip: 0,
      });
    },
    [replace]
  );

  const handleVisitedChange = useCallback(() => {
    const { name, limit, noVisited } = getSearchState();

    push(
      {
        name,
        skip: 0,
        limit,
        noVisited: noVisited === "true" ? false : true,
      },
      true
    );
  }, [push]);

  const handleRecentlyAddedChange  = useCallback(() => {
    const { name, limit, recentlyAdded } = getSearchState();

    push(
      {
        name,
        skip: 0,
        limit,
        recentlyAdded: recentlyAdded  === "true" ? false : true,
      },
      true
    );
  }, [push]);

  const changePaginationMeta = useCallback(
    (meta: Partial<PaginationInfo>) => {
      push(meta);
    },
    [push]
  );

  const handleClearFilters = useCallback(() => {
    const { name, limit } = getSearchState();
    push(
      {
        name,
        skip: 0,
        limit,
        noVisited: false,
        recentlyAdded: true,
      },
      true
    );
  }, [push]);

  const handleResetFilters = useCallback(() => {
    const { limit } = getPaginationInfo();
    push({ limit, skip: 0 }, true);
  }, [push]);

  const handlePageChanged = useCallback(
    (skip: number) => {
      push({ skip });
    },
    [push]
  );

  return {
    handlePageChanged,
    handleFiltersChanged,
    handleClearFilters,
    handleResetFilters,
    changePaginationMeta,
    handleVisitedChange,
    handleRecentlyAddedChange
  };
}
