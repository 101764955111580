import { styled } from "@linaria/react";
import React, {
  ComponentType,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Snackbar from "./Snackbar";
import { SnackbarComponentProps, SnackbarMessage } from "./snackbar.interfaces";
import { snackbarAnimationClass } from "./SnackbarAnimation";
import { SnackbarPortal } from "./SnackbarPortal";

type Key = string;
type SnackbarOptions = {
  key?: Key;
  preventDuplicates?: boolean;
  timeout?: number | false;
};

type SnackbarContextValue = {
  enqueueSnackbar: (
    message: string,
    options?: SnackbarOptions
  ) => Key | undefined;
  hideSnackbar: (key: string) => void;
};

const SnackbarContext = React.createContext<SnackbarContextValue | null>(null);

const SnackbarsRoot = styled.div`
  position: fixed;

  left: 0;
  right: 0;
  bottom: 0;

  z-index: 99999;
`;

const defaultTimeout = 3000;

interface SnackbarProviderProps {
  className?: string;
  animationClassName?: string;
  children?: React.ReactNode;
  SnackbarComponent?: ComponentType<SnackbarComponentProps>;
}

interface StoredSnackbar extends SnackbarMessage {
  timer?: number;
}

export const SnackbarProvider = ({
  children,
  className,
  animationClassName = snackbarAnimationClass,
  SnackbarComponent = Snackbar,
}: SnackbarProviderProps) => {
  const [snackbars, setSnackbars] = useState<StoredSnackbar[]>([]);
  const snackbarsRef = useRef<StoredSnackbar[]>(snackbars);

  useEffect(() => {
    snackbarsRef.current = snackbars;
  }, [snackbars]);

  const hideSnackbar = useCallback<SnackbarContextValue["hideSnackbar"]>(
    (key) => {
      const snackbarIndex = snackbarsRef.current.findIndex(
        (snackbar) => snackbar.key === key
      );

      if (snackbarIndex === -1) {
        return;
      }

      const snackbar = snackbarsRef.current[snackbarIndex];

      if (typeof snackbar.timer !== "undefined") {
        clearTimeout(snackbar.timer);
      }
      snackbarsRef.current.splice(snackbarIndex, 1);

      setSnackbars([...snackbarsRef.current]);
    },
    []
  );

  const enqueueSnackbar = useCallback<SnackbarContextValue["enqueueSnackbar"]>(
    (text, options) => {
      const key = options?.key || Date.now().toString();
      const timeout =
        options?.timeout === false
          ? undefined
          : options?.timeout || defaultTimeout;
      if (
        options?.preventDuplicates &&
        !!snackbarsRef.current.find((item) => item.key === key)
      ) {
        hideSnackbar(key);
      }

      let timer: number | undefined = undefined;
      if (typeof timeout === "number") {
        timer = window.setTimeout(() => hideSnackbar(key), timeout);
      }
      setSnackbars([...snackbarsRef.current, { text, key, timer }]);
      return key;
    },
    [hideSnackbar]
  );

  const value = useMemo(() => ({ enqueueSnackbar, hideSnackbar }), [
    enqueueSnackbar,
    hideSnackbar,
  ]);

  return (
    <SnackbarContext.Provider value={value}>
      {children}

      <SnackbarPortal>
        <SnackbarsRoot className={className}>
          <TransitionGroup component={null}>
            {snackbars.map((snackbar) => (
              <CSSTransition
                timeout={300}
                classNames={animationClassName}
                key={snackbar.key}
              >
                <SnackbarComponent message={snackbar} onClose={hideSnackbar} />
              </CSSTransition>
            ))}
          </TransitionGroup>
        </SnackbarsRoot>
      </SnackbarPortal>
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  const value = useContext(SnackbarContext);

  return value as SnackbarContextValue;
};
