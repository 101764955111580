import * as React from "react";

const SvgZoomMinus = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 20 1"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M19.409.5H.59" stroke="currentColor" strokeLinecap="square" />
  </svg>
);

export default SvgZoomMinus;
