import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import rootReducer, { RootState } from "./rootReducer";

const store = configureStore({
  reducer: rootReducer,
});

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./rootReducer", () => {
    const newRootReducer = require("./rootReducer").default;
    store.replaceReducer(newRootReducer);
  });
}

export type AppDispatch = typeof store.dispatch;

export default store;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export type FetchStatus = null | "success" | "error" | "loading";
export type ChatCredentialType = {
  id: string;
  token: string;
  companionId: string;
  channelId: string;
};
