import { Artwork } from "features/Artwork/artwork.interfaces";
import { ViewingRoom } from "features/ViewingRoom/interfaces/viewing-room.interfaces";
import { api } from "index";
import { ArtistItem } from "interfaces/artist.interfaces";
import { stringify } from "query-string";
import { useQuery } from "react-query";
import { SHALLOW_SEARCH_QUERY_KEY } from "..";

const shallowSearchRequest = (searchString: string) => {
  return api
    .get(`/api/v1/user/shallow?${stringify({ name: searchString })}`)
    .json<{
      viewingRooms: ViewingRoom[];
      artworks: Artwork[];
      artists: ArtistItem[];
    }>();
};

export default function useShallowSearchQuery(searchString: string) {
  return useQuery(
    [SHALLOW_SEARCH_QUERY_KEY, searchString],
    () => shallowSearchRequest(searchString),
    {
      enabled: false,
    }
  );
}
