import * as React from "react";

const SvgSave = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    className="outlined"
    width={14}
    height={22}
    viewBox="0 0 14 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M1 1.429h12v19.183l-5.971-4.24L1 20.611V1.43z"
      stroke="currentColor"
      strokeWidth={1.1}
    />
  </svg>
);

export default SvgSave;
