import clsx from "clsx";
import React from "react";
import classes from "./PostSignUp.module.scss";

const PostSignUp = () => (
  <div className={classes.root}>
    <h2 className={clsx(classes.body1)}>
      Thank you for registering. <br />
      Please tap on the back button to sign in.
    </h2>
  </div>
);
export default PostSignUp;
