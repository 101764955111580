import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  opened: false,
};

const { reducer, actions } = createSlice({
  initialState,
  name: "mobileMenu",
  reducers: {
    open(state) {
      state.opened = true;
    },
    close(state) {
      state.opened = false;
    },
  },
});

export default reducer;
export const { open: openMobileMenu, close: closeMobileMenu } = actions;
