import * as React from "react";

const SvgClose = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    className="outline"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 29 29"
    {...props}
  >
    <circle cx={14.5} cy={14.5} r={14} className="filled" stroke="#000" />
    <path
      d="M8.295 8.31l12.41 12.38M21 8L8.867 21"
      stroke="#000"
      strokeLinecap="square"
    />
  </svg>
);

export default SvgClose;
