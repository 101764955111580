import { artworkRouteConfig } from "features/Artwork/artwork.routes";
import { logoutAsAdmin } from "features/Auth/store/authSlice";
import ChatProvider from "features/Chat/components/ChatProvider";
import { homeRouteConfig } from "features/Home/home.routes";
import { viewingRoomRoutes } from "features/ViewingRoom/viewing-room.routes";
import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { RootState } from "store/rootReducer";

const ViewingRoomView = React.lazy(
  () => import("features/ViewingRoom/view/ViewingRoomView")
);
const ArtDetailView = React.lazy(
  () => import("features/Artwork/ArtDetailView")
);

const PreviewContent = () => {
  const { search } = useLocation();
  const { previewRoomId } = useSelector((state: RootState) => state.auth);

  if (!previewRoomId) {
    logoutAsAdmin();
  }

  return (
    <Suspense fallback={null}>
      <ChatProvider>
        <Switch>
          <Route path={artworkRouteConfig.path} component={ArtDetailView} />
          <Route path={viewingRoomRoutes.path} component={ViewingRoomView} />

          <Redirect
            to={
              previewRoomId
                ? viewingRoomRoutes.link(previewRoomId)
                : `${homeRouteConfig.link}${search}`
            }
          />
        </Switch>
      </ChatProvider>
    </Suspense>
  );
};

export default PreviewContent;
