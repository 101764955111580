import { styled } from "@linaria/react";
import clsx from "clsx";
import SvgProfileFilled from "components/icons/ProfileFilled";
import SvgProfileOutlined from "components/icons/ProfileOutlined";
import NavigationLink from "features/Navigation/components/NavigationLink";
import { personalDataRoute } from "features/Profile/profile.routes";
import React from "react";
import { useHistory } from "react-router-dom";
import { HeaderButtonProps } from "../header.interfaces";
import { headerActionCss } from "./HeaderAction";

const StyledButton = styled.button`
  cursor: pointer;
`;

const ProfileButton = ({ className, onClick, active }: HeaderButtonProps) => {
  const history = useHistory();
  if (!active) {
    return (
      <NavigationLink
        aria-label="Profile"
        to={personalDataRoute.link}
        onClick={onClick}
        addGoBackState
        saveScroll
        className={clsx(className, headerActionCss)}
      >
        <SvgProfileOutlined height="24" />
      </NavigationLink>
    );
  }
  return (
    <StyledButton aria-label="Profile" onClick={() => history.goBack()}>
      <SvgProfileFilled height="24" />
    </StyledButton>
  );
};

export default ProfileButton;
