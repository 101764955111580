import clsx from "clsx";
import BurgerMenuDesktop from "components/icons/BurgerMenuDesktop";
import SvgCross from "components/icons/Cross";
import SvgBurgerMobile from "components/icons/SvgBurgerMobile";
import React from "react";
import { up } from "styles/utils/breakpoints";
import useBreakpoint from "styles/utils/useBreakpoint";
import { HeaderButtonProps } from "../header.interfaces";
import HeaderAction from "./HeaderAction";

const MenuButton = ({
  active,
  onClick,
  onEnter,
  className,
}: HeaderButtonProps) => {
  const upLg = useBreakpoint(up("lg"));
  return (
    <HeaderAction
      className={clsx(!active ? "menu" : "cross", className)}
      aria-label={active ? "close menu" : "open menu"}
      onClick={onClick}
    >
      {!active ? (
        upLg ? (
          <BurgerMenuDesktop color="#000" height="17" />
        ) : (
          <SvgBurgerMobile color="#000" height="18" />
        )
      ) : (
        <SvgCross className="cross" />
      )}
    </HeaderAction>
  );
};

export default MenuButton;
