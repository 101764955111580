export interface IViewingRoomStorage {
  getIds(): number[];
  addId(id: number): void;
  setIds(ids: number[]): void
}

const storage = localStorage;

const viewingRoomStorage: IViewingRoomStorage = {
  getIds() {
    const ids = storage.getItem('visited-rooms');
    return ids ? JSON.parse(ids) : []
  },
  addId(id: number) {
    const ids = storage.getItem('visited-rooms');
    const parsedIds = ids ? JSON.parse(ids) : []
    if (parsedIds.includes(id)) return 

    const newIds = parsedIds.concat(id)
    storage.setItem('visited-rooms', JSON.stringify(newIds));
  },
  setIds(ids: number[]) {
    storage.setItem('visited-rooms', JSON.stringify(ids));
  }
}

export default viewingRoomStorage