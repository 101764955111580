export interface IRoomStorage {
  getRoomId(): string | null;
  setRoomId(id: string): void;
  removeRoomId(): void;
}

const storage = sessionStorage;

const previewRoomStorage: IRoomStorage = {
  getRoomId() {
    return storage.getItem("room-preview");
  },
  setRoomId(id) {
    storage.setItem("room-preview", id);
  },
  removeRoomId() {
    storage.removeItem("room-preview");
  },
};

export default previewRoomStorage;
