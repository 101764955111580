import { viewingRoomRoutes } from "features/ViewingRoom/viewing-room.routes";
import { LocationDescriptorObject } from "history";
export const artworkRouteConfig = {
  link: (vrId: string | number, artId: string | number) =>
    `${viewingRoomRoutes.link(vrId)}/art/${artId}`,
  path: `/viewing-room/:viewingRoomId/art/:artId`,
};

export const artworkViewerRouteConfig = {
  link() {
    return "#viewer";
  },
  path: "#viewer",
};

export const shareArtModalRoute = {
  link(location: LocationDescriptorObject): LocationDescriptorObject {
    return { ...location, hash: "#share" };
  },
  path: "#share",
};
