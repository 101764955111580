import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { paginationLimit } from "config";
import { PaginationInfo } from "features/Pagination/interfaces/pagination.interfaces";
import { SearchFilters, SearchSections } from "../interfaces/search.interfaces";
const initialPaginationInfo: PaginationInfo = {
  skip: 0,
  limit: paginationLimit,
};

const initialFilters: SearchFilters = {
  name: "",
  priceRanges: [],
  mediums: [],
  centuryRanges: [],
  sections: [],
  regions: [],
  categories: [],
  artistBirthPlaces: [],
  sortBy: "asc",
  isPurchasableOnline: "false",
  years: [],
};

const initialState: {
  filters: SearchFilters;
  paginationMeta: Record<SearchSections, PaginationInfo>;
} = {
  filters: initialFilters,
  paginationMeta: {
    artworks: initialPaginationInfo,
    viewingRooms: initialPaginationInfo,
  },
};

const { actions, reducer } = createSlice({
  name: "search",
  initialState,
  reducers: {
    changePage(
      state,
      action: PayloadAction<{ section: SearchSections; skip: number }>
    ) {
      const {
        payload: { skip, section },
      } = action;
      state.paginationMeta[section].skip = skip;
    },
    changeFilters(
      state,
      action: PayloadAction<{ filter: Partial<SearchFilters> }>
    ) {
      state.filters = {
        ...state.filters,
        ...action.payload.filter,
      };
      state.paginationMeta.artworks.skip = 0;
      state.paginationMeta.viewingRooms.skip = 0;
    },
    resetFilters(state) {
      state.filters = {
        ...initialFilters,
        name: state.filters.name,
        artists: state.filters.artists,
      };
      state.paginationMeta = { ...initialState.paginationMeta };
    },
    setPaginationMeta(
      state,
      action: PayloadAction<{
        section: SearchSections;
        meta: Partial<PaginationInfo>;
      }>
    ) {
      const { section, meta } = action.payload;
      state.paginationMeta[section] = {
        ...state.paginationMeta[section],
        ...meta,
      };
    },
    clearFilters(state) {
      state.filters = {
        ...initialFilters,
      };
      state.paginationMeta = { ...initialState.paginationMeta };
    },

    clear() {
      return initialState;
    },
  },
});

export const {
  changePage,
  clear,
  changeFilters,
  clearFilters,
  resetFilters,
  setPaginationMeta,
} = actions;

export default reducer;
