import * as React from "react";

const SvgBookmarkOutline = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    className="filled"
    height={19}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 19"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M1.523 0h13.229c.313 0 .57.242.575.537v17.456c0 .377-.221.72-.58.894a1.11 1.11 0 01-1.11-.077l-5.5-3.709-5.494 3.709a1.1 1.1 0 01-.622.189c-.164 0-.333-.04-.487-.112a.997.997 0 01-.58-.894V.537c0-.295.256-.537.57-.537zm12.659 17.852V1.078H2.093v16.78l5.71-3.86a.6.6 0 01.668 0l5.71 3.854z"
    />
  </svg>
);

export default SvgBookmarkOutline;
