import React from "react";
import { down } from "styles/utils/breakpoints";
import useBreakpoint from "styles/utils/useBreakpoint";
import DesktopHeader from "./DesktopHeader";
import MobileHeader from "./MobileHeader";

export type HeaderSwitchProps = {
  Mobile?: React.ComponentType<any>;
  Desktop?: React.ComponentType<any>;
};

const HeaderSwitch = ({
  Mobile = MobileHeader,
  Desktop = DesktopHeader,
}: HeaderSwitchProps) => {
  const downSm = useBreakpoint(down("lg"));
  const mobileNavigation = downSm;

  if (mobileNavigation) {
    return <Mobile />;
  } else {
    return <Desktop />;
  }
};

export default HeaderSwitch;
