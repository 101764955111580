import * as React from "react";

const SvgProfileFilled = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    className="filled"
    viewBox="0 0 19 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask id="a" maskUnits="userSpaceOnUse" x={4} y={0} width={11} height={12}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.004.002h10.994v11.994H4.004V.002z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.501.879a4.33 4.33 0 00-1.827.402A4.679 4.679 0 006.182 2.38a5.152 5.152 0 00-1.006 1.627A5.49 5.49 0 004.808 6c0 .691.124 1.362.368 1.993.237.61.575 1.157 1.006 1.628.431.47.933.84 1.492 1.097a4.33 4.33 0 001.827.402 4.33 4.33 0 001.827-.402A4.679 4.679 0 0012.82 9.62c.431-.47.77-1.018 1.006-1.628a5.49 5.49 0 00.368-1.993 5.49 5.49 0 00-.368-1.993A5.152 5.152 0 0012.82 2.38a4.678 4.678 0 00-1.492-1.098A4.33 4.33 0 009.5.88zm0 11.117a5.073 5.073 0 01-2.14-.471 5.478 5.478 0 01-1.747-1.286 6.033 6.033 0 01-1.178-1.905 6.431 6.431 0 01-.432-2.335c0-.81.145-1.595.432-2.334a6.034 6.034 0 011.178-1.906A5.479 5.479 0 017.361.474a5.073 5.073 0 012.14-.472c.742 0 1.462.159 2.14.472a5.478 5.478 0 011.747 1.285c.505.55.901 1.192 1.178 1.906.287.74.432 1.525.432 2.334 0 .81-.145 1.595-.432 2.335a6.032 6.032 0 01-1.178 1.905 5.477 5.477 0 01-1.747 1.286 5.073 5.073 0 01-2.14.471z"
        fill="#000"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.819 22.14h17.362v-3.492c-1.71-1.758-3.655-2.918-5.784-3.45-1.675-.417-3.45-.448-5.272-.09a11.68 11.68 0 00-2.757.912c-.704.334-1.378.74-2.004 1.207a10.23 10.23 0 00-1.545 1.4v3.512zM19 23H0v-4.69l.094-.12c.025-.031.622-.784 1.77-1.645.67-.503 1.393-.94 2.147-1.3.944-.45 1.94-.781 2.963-.982 1.937-.381 3.825-.348 5.612.098 2.329.582 4.446 1.86 6.294 3.803l.12.126V23z"
      fill="#000"
    />
  </svg>
);

export default SvgProfileFilled;
