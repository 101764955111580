import useGoBackTo from "features/Navigation/hooks/useGoBackTo";
import ScrollLink from "features/ScrollController/ScrollLink/ScrollLink";
import React, { ComponentProps, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";

export type NavigationLinkProps = ComponentProps<typeof ScrollLink> & {
  goBack?: boolean;
  addGoBackState?: boolean;
  disabled?: boolean;
};

export type GoBackState = { goBack?: boolean } | null;

const NavigationLink = ({
  onClick,
  goBack,
  to,
  addGoBackState,
  disabled,
  ...props
}: NavigationLinkProps) => {
  const { goBack: historyGoBack } = useHistory<{ goBack?: boolean }>();
  const location = useLocation<{ goBack?: boolean } | null>();
  const { state } = location;

  const goBackTo = useGoBackTo(to, location);

  const handleDisableClick = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
  }, []);

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      if (state?.goBack && goBack) {
        historyGoBack();
        e.preventDefault();
        e.stopPropagation();
      }
      if (onClick) {
        onClick(e);
      }
    },
    [goBack, historyGoBack, onClick, state]
  );

  return (
    <ScrollLink
      onClick={disabled ? handleDisableClick : handleClick}
      to={addGoBackState ? goBackTo : to}
      {...props}
    />
  );
};

export default NavigationLink;
