import { styled } from "@linaria/react";
import BaseHeader, {
  BaseHeaderProps,
  HeaderPlaceholder,
} from "components/Header/BaseHeader/BaseHeader";
import useMobileMenu from "features/MobileMenu/hooks/useMobileMenu";
import HeaderNavigation from "features/Navigation/HeaderMainNavigation";
import useShallowSearch from "features/Search/hooks/useShallowSearch";
import React from "react";
import DesktopHeaderSearch from "./components/Desktop/HeaderSearchDesktop";
import HeaderControls from "./components/HeaderControls";

export interface DesktopHeaderProps extends Omit<BaseHeaderProps, "Controls"> {
  Navigation?: React.ComponentType<any>;
  Controls?: BaseHeaderProps["Controls"];
}

const StyledBaseHeader = styled(BaseHeader)`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
`;

const DesktopHeader = ({
  className,
  Navigation = HeaderNavigation,
  Controls = HeaderControls,
  Decoration,
}: DesktopHeaderProps) => {
  const { opened } = useMobileMenu();
  const {
    searchOverlayState: { active },
  } = useShallowSearch();

  return (
    <>
      <StyledBaseHeader
        Decoration={Decoration}
        className={className}
        Controls={Controls}
      >
        {(opened || active) && (
          <>
            {opened && <Navigation />}
            {active && <DesktopHeaderSearch />}
          </>
        )}
      </StyledBaseHeader>
      <HeaderPlaceholder />
    </>
  );
};

export default DesktopHeader;
