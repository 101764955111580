import clsx from "clsx";
import React from "react";
import { VISUALLY_HIDDEN } from "styles";
import mergeClasses from "utils/mergeClasses";
import classes from "./Checkbox.module.scss";

export type CheckboxProps = React.InputHTMLAttributes<HTMLInputElement> & {
  classes?: Partial<{
    input: string;
    checkmark: string;
    root: string;
    filled: string;
  }>;
  variant?: "outlined" | "filled";
};

const Checkbox = ({
  className,
  classes: externalClasses,
  variant = "outlined",
  ...props
}: CheckboxProps) => {
  const resultClasses = mergeClasses(classes, externalClasses);

  return (
    <div
      className={clsx(resultClasses.root, className, {
        [resultClasses.filled as any]: variant === "filled",
      })}
    >
      <input
        type="checkbox"
        className={clsx(VISUALLY_HIDDEN, resultClasses.input)}
        {...props}
      />
      <span className={resultClasses.checkmark}></span>
    </div>
  );
};

export default Checkbox;
