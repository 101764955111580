import { matchPath, useLocation } from "react-router-dom";
import { artworkRouteConfig } from "../artwork.routes";

export default function useGetCurrentArtId() {
  const location = useLocation();
  const { artId } = matchPath<{ artId: string }>(location.pathname, {
    path: artworkRouteConfig.path,
    exact: false,
    strict: false,
  })?.params || { artId: "" };
  return artId;
}
