import React, {
  PropsWithChildren,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  NavSectionType,
  NestedNavigationContextValue,
} from "../nested-navigation.interfaces";

const NestedNavigationContext = React.createContext<NestedNavigationContextValue | null>(
  null
);

type NestedNavigationProviderProps = PropsWithChildren<{}>;

const NestedNavigationProvider = (props: NestedNavigationProviderProps) => {
  const [selectedSection, setSelectedSection] = useState<
    NavSectionType | undefined
  >();
  const mouseOverNavigationRef = useRef(false);

  const value = useMemo<NestedNavigationContextValue>(
    () => ({
      selectSection: setSelectedSection,
      selectedSection,
      mouseOverNavigationRef,
    }),
    [selectedSection]
  );
  return (
    <NestedNavigationContext.Provider value={value}>
      {props.children}
    </NestedNavigationContext.Provider>
  );
};

export const useNestedNavigation = () =>
  useContext(NestedNavigationContext) as NestedNavigationContextValue;

export function NestedNavigationProviderHOC<T>(
  Component: React.ComponentType<T>
): React.ComponentType<T> {
  return (props: T) => (
    <NestedNavigationProvider>
      <Component {...props} />
    </NestedNavigationProvider>
  );
}

export default NestedNavigationProvider;
