import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import { closeMobileMenu, openMobileMenu } from "../store/mobileMenuStore";

export default function useMobileMenu() {
  const opened = useSelector((root: RootState) => root.mobileMenu.opened);
  const dispatch = useDispatch();


  const open = useCallback(() => {
    dispatch(openMobileMenu());
  }, [dispatch]);

  const close = useCallback(() => {
    dispatch(closeMobileMenu());
  }, [dispatch]);

  const toggle = useCallback(() => {
    opened ? close() : open();
  }, [close, open, opened]);

  return { opened, open, close, toggle };
}
