import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/rootReducer";

export type AuthViewProps = {
  publicView: React.ReactNode;
  secureView: React.ReactNode;
};

const AuthView = ({ publicView, secureView }: AuthViewProps) => {
  const loggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
  return <>{loggedIn ? secureView : publicView}</>;
};

export default AuthView;
