import usePreviewSession from "features/Auth/hooks/usePreviewSession";
import useQueryString from "hooks/useQueryString";
import { useEffect, useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";

const ViewingRoomPreview = () => {
  const { token, next } = useQueryString();
  const { loginAdmin, isPreview } = usePreviewSession();
  const { push } = useHistory();

  useLayoutEffect(() => {
    if (token && next) {
      loginAdmin(token as string);
    }
  }, [loginAdmin, next, push, token]);

  useEffect(() => {
    if (isPreview) {
      push(next as string);
    }
  }, [isPreview, next, push]);

  return null;
};

export default ViewingRoomPreview;
