import { styled } from "@linaria/react";
import SubheaderBase from "features/Header/SubheaderBase/SubheaderBase";

const NestedList = styled(SubheaderBase)`
  position: absolute;
  top: var(--header-height);
  left: 0;
  right: 0;
  background-color: #fff;
  transition: opacity 100ms ease-in-out;
  border-bottom: 1px solid #000;
  display: flex;
  align-items: stretch;
  justify-content: center;
  width: 100%;

  //fix: secondary nav bar closes on mouseover
  &::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
    left: 0;
    top: -1px;
  }
`;

export default NestedList;
