import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import clsx from "clsx";
import React, { ComponentProps } from "react";
import { zIndex } from "styles";
import { up } from "styles/utils/breakpoints";

const SubheaderBase = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 62px;
  padding-bottom: 20px;


  background-color: #ffffff;

  ${up('lg')} {
    border-bottom: 1px solid black;
    height: var(--subheader-height, 47px);
    padding-bottom: 0;
  }
`;

const SubheaderPlaceholder = styled.div`
  height: var(--subheader-height);
  flex-shrink: 0;
`;

const fixedCss = css`
  position: fixed;
  top: var(--header-height);
  left: 0;
  right: 0;
  z-index: ${zIndex.header - 1};

  text-transform: uppercase;
`;

export const FixedSubheader = ({
  className,
  ...props
}: ComponentProps<typeof SubheaderBase>) => {
  return (
    <>
      <SubheaderBase className={clsx(fixedCss, className)} {...props} />
      <SubheaderPlaceholder />
    </>
  );
};

export default SubheaderBase;
