import { styled } from "@linaria/react";
import ReactModalAdapter, {
  contentSelector,
  overlaySelector,
  ReactModalAdapterProps,
} from "components/ReactModalAdapter";
import MobileMenuFooter from "features/Navigation/MobileMenuFooter";
import useFixBody from "hooks/useFixBody";
import React from "react";

type MobileNavigationMenuProps = Pick<
  ReactModalAdapterProps,
  "isOpen" | "onRequestClose" | "children"
>;

const StyledModalAdapter = styled(ReactModalAdapter)`
  ${overlaySelector} {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 101px;
    z-index: 1000;

    transition: transform 300ms ease-in-out;

    transform: translate(-100%, 0);

    &.ReactModal__Overlay--after-open {
      transform: translate(0, 0);
    }
    &.ReactModal__Overlay--before-close {
      transform: translate(-100%, 0);
    }
    overflow: auto;
  }
  ${contentSelector} {
    display: flex;
    flex-direction: column;
    padding-top: 13px;
    padding-bottom: 13px;


    min-height: 100%;
    background-color: #ffffff;
    .dark & {
      background-color: #000000;
      color: #ffffff;
    }
  }
`;

const MobileNavigationMenu = ({
  children,
  ...props
}: MobileNavigationMenuProps) => {
  useFixBody(props.isOpen);

  return (
    <StyledModalAdapter closeTimeoutMS={300} {...props}>
      {children}
      <MobileMenuFooter />
    </StyledModalAdapter>
  );
};

export default MobileNavigationMenu;
