import React from "react";
import { FieldRenderProps } from "react-final-form";
import Checkbox from "../Checkbox";

type FinalFormCheckboxProps = FieldRenderProps<HTMLInputElement["value"]> &
  React.ComponentProps<typeof Checkbox>;

const FinalFormCheckbox = ({ input, ...rest }: FinalFormCheckboxProps) => {
  return <Checkbox {...input} {...rest} />;
};

export default FinalFormCheckbox;
