import { matchPath, useLocation } from "react-router-dom";
import { viewingRoomRoutes } from "../viewing-room.routes";

export default function useViewingRoomId() {
  const location = useLocation();
  const { viewingRoomId } = matchPath<{ viewingRoomId: string }>(
    location.pathname,
    {
      path: viewingRoomRoutes.path,
      exact: false,
      strict: false,
    }
  )?.params || { viewingRoomId: "" };
  return viewingRoomId;
}
