import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { parse } from "utils/query-string";

interface UseNextOptions {
  stop?: string[];
}

export default function useNext({ stop }: UseNextOptions = {}) {
  const { search } = useLocation();

  const { replace } = useHistory();
  useEffect(() => {
    const parsed = parse(search);
    if (!!parsed?.next && !stop?.some((stopWord) => !!parsed[stopWord])) {
      const [pathname, search] = decodeURI(parsed.next).split("?");
      replace({ pathname, search: encodeURI(search) });
    }
  }, [replace, search, stop]);
}
