import clsx from "clsx";
import React, { useCallback, useMemo, useRef } from "react";
import { Transition } from "react-transition-group";
import {
  EnterHandler,
  ExitHandler,
  TransitionProps,
} from "react-transition-group/Transition";
import mergeClasses from "utils/mergeClasses";
import classes from "./Collapse.module.scss";

type CollapseProps = TransitionProps & {
  classes?: Partial<typeof classes>;
  collapsedHeight?: string;
};

const Collapse = ({
  timeout,
  children,
  in: inProp,
  classes: externalClasses,
  onEntering,
  onEntered,
  onExit,
  onExiting,
  collapsedHeight = "0px",
  onEnter,
  style,
  ...props
}: CollapseProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const resultClasses = useMemo(() => mergeClasses(classes, externalClasses), [
    externalClasses,
  ]);

  const defaultStyle = useMemo(
    () => ({
      transition: `height ${timeout}ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
      ...style,
      minHeight: collapsedHeight,
    }),
    [collapsedHeight, style, timeout]
  );

  const handleEnter: EnterHandler<undefined> = (node, isAppearing) => {
    node.style.height = collapsedHeight;

    if (onEnter) {
      onEnter(node, isAppearing);
    }
  };

  const handleEntering: EnterHandler<undefined> = useCallback(
    (node, isAppearing) => {
      const wrapperHeight = wrapperRef.current
        ? wrapperRef.current.clientHeight
        : 0;
      node.style.height = `${wrapperHeight}px`;
      if (onEntering) {
        onEntering(node, isAppearing);
      }
    },
    [onEntering]
  );

  const handleEntered: EnterHandler<undefined> = useCallback(
    (node, isAppearing) => {
      // node.style.height = "auto";
      // node.style.height = `${wrapperHeight}px`;
      if (onEntered) {
        onEntered(node, isAppearing);
      }
    },
    [onEntered]
  );

  const handleExit: ExitHandler<undefined> = (node) => {
    const wrapperHeight = wrapperRef.current
      ? wrapperRef.current.clientHeight
      : 0;

    node.style.height = `${wrapperHeight}px`;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const i = node.scrollTop;

    if (onExit) {
      onExit(node);
    }
  };

  const handleExiting: ExitHandler<undefined> = (node) => {
    node.style.height = collapsedHeight;

    if (onExiting) {
      onExiting(node);
    }
  };

  return (
    <Transition
      timeout={timeout}
      in={inProp}
      onEnter={handleEnter}
      onEntering={handleEntering}
      onEntered={handleEntered}
      onExit={handleExit}
      onExiting={handleExiting}
      {...(props as TransitionProps)}
    >
      {(state) => {
        return (
          <div
            className={clsx(resultClasses.root, {
              [resultClasses.rootEntered]: state === "entered",

              [resultClasses.hidden]:
                state === "exited" && !inProp && collapsedHeight === "0px",
            })}
            style={{
              ...defaultStyle,
            }}
          >
            <div ref={wrapperRef} className={resultClasses.wrapper}>
              <div className={resultClasses.wrapperInner}>{children}</div>
            </div>
          </div>
        );
      }}
    </Transition>
  );
};

export default Collapse;
