import * as React from "react";

const MailSvg = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    className="outline"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1000 1000"
    {...props}
  >
    <g>
      <path d="M856.4,99.1H143.6C69.8,99.1,10,158.9,10,232.7v534.5c0,73.8,59.8,133.6,133.6,133.6h712.7c73.8,0,133.6-59.8,133.6-133.6V232.7C990,158.9,930.2,99.1,856.4,99.1z M856.4,188.2c5.1,0,10.1,0.9,14.7,2.5L500,487.5L129,190.7c4.6-1.6,9.5-2.5,14.7-2.5L856.4,188.2L856.4,188.2z M856.4,811.8H143.6c-24.6,0-44.5-19.9-44.5-44.5V280.9l373.1,298.5c8.1,6.5,18,9.8,27.8,9.8s19.7-3.3,27.8-9.8l373.1-298.5v486.4C900.9,791.9,881,811.8,856.4,811.8z" />
    </g>
  </svg>
);

export default MailSvg;
