import * as React from "react";

const SvgArtRoomCross = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    className="filled"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.549 0L9.876 8.891 1.4.204l-.71.73L9.164 9.62 0 19.016l.712.73 9.164-9.395L19.288 20l.712-.73-9.413-9.649L19.261.73 18.549 0z"
      fill="#000"
    />
  </svg>
);

export default SvgArtRoomCross;
