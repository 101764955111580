import useMobileMenu from "features/MobileMenu/hooks/useMobileMenu";
import { savedGalleries } from "features/PlanYourVisit/plan-your-visit.routes";
import { personalDataRoute } from "features/Profile/profile.routes";
import useConstants from "features/ServerConstants/hooks/useConstants";
import React, { useCallback, useState } from "react";
import MobileNavigationListItem, {
  NestedListItemText,
} from "./components/MobileNavigationListItem";
import NavigationLink from "./components/NavigationLink";
import { styled } from "@linaria/react";
import { cleanCurrentLink, isExternalLink } from "utils/isExternalLink";

const ExternaLink = styled.a``;

const StyledUl = styled.ul`
  button {
    text-align: left;
  }
`;

const MobileNav = styled.nav`
  padding-top: 20px;
  border-top: 1px solid #000000;
  padding-inline: 30px 20px;
`

const MobileMainNavigation = () => {
  const { data: constants } = useConstants();
  const { close } = useMobileMenu();

  const [expandedId, setExpandedId] = useState<string | number>();

  const handleClickListItem = useCallback(
    (id?: string | number) => {
      if (expandedId === id) {
        setExpandedId(undefined);
      } else {
        setExpandedId(id);
      }
    },
    [expandedId]
  );

  return (
    <MobileNav>
      <StyledUl>
        {constants?.NavigationItem.map((navItem) =>
          navItem.type === "link" ? (
            // JUST A LINK
            isExternalLink(navItem.value) ? (
              <MobileNavigationListItem
                arrow={false}
                text={
                  <ExternaLink href={navItem.value}>{navItem.name}</ExternaLink>
                }
              />
            ) : (
              <MobileNavigationListItem
                arrow={false}
                text={
                  <NavigationLink addGoBackState to={cleanCurrentLink(navItem.value)}>
                    {navItem.name}
                  </NavigationLink>
                }
              />
            )
          ) : (
            // LINK WITH CHILDRENS
            <MobileNavigationListItem
              text={<p>{navItem.name}</p>}
              expandId={expandedId}
              id={navItem.id}
              onClick={handleClickListItem}
            >
              {!!navItem.items.length && (
                <StyledUl>
                  {navItem.items.map((subItem) => (
                    <NestedListItemText key={subItem.id}>
                      {isExternalLink(subItem.value) && (
                        <ExternaLink href={subItem.value}>
                          {subItem.name}
                        </ExternaLink>
                      )}

                      {!isExternalLink(subItem.value) && (
                        <NavigationLink
                          addGoBackState
                          to={cleanCurrentLink(subItem.value)}
                          onClick={close}
                        >
                          {subItem.name}
                        </NavigationLink>
                      )}
                    </NestedListItemText>
                  ))}
                </StyledUl>
              )}
            </MobileNavigationListItem>
          )
        )}

        {/* HARDCODED MENU */}
        <MobileNavigationListItem
          expandId={expandedId}
          onClick={handleClickListItem}
          id="my-account"
          text="My Account"
        >
          <StyledUl>
            <li>
              <NavigationLink
                onClick={close}
                addGoBackState
                to={personalDataRoute.path}
              >
                Overview
              </NavigationLink>
            </li>
            <li>
              <NavigationLink
                onClick={close}
                addGoBackState
                to={savedGalleries.link}
              >
                Saved Galleries
              </NavigationLink>
            </li>
          </StyledUl>
        </MobileNavigationListItem>
      </StyledUl>
    </MobileNav>
  );
};

export default MobileMainNavigation;
