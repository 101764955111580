import qs from "qs";

export function parse<T = any>(str: string, options?: qs.IParseOptions) {
  if (str[0] === "?") {
    str = str.slice(1);
  }

  return (qs.parse(str, {
    interpretNumericEntities: true,
    ...options,
  }) as unknown) as T;
}

export function stringify<T = any>(input: T, options?: qs.IStringifyOptions) {
  return qs.stringify(input, options);
}
