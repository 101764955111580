export type EventListener<T> = (args: T) => any;

export default class CustomEvent<T> {
  private _listeners: Set<EventListener<T>>;

  constructor() {
    this._listeners = new Set<EventListener<T>>();
  }

  addListener(listener: EventListener<T>) {
    this._listeners.add(listener);
  }

  removeListener(listener: EventListener<T>) {
    return this._listeners.delete(listener);
  }

  dispatchEvent(args: T) {
    this._listeners.forEach((listener) => listener(args));
  }

  dispose() {
    this._listeners.clear();
  }
}
