import RootLayout from "components/Layout/RootLayout/RootLayout";
import { artworkRouteConfig } from "features/Artwork/artwork.routes";
import PublicArtworkView from "features/Artwork/ArtworkDetailMobile/PublicArtworkView";
import ClosedView from "features/Closed/ClosedView";
import {
  loginRoute,
  registrationRoute,
  requestRestoreLink,
  restorePasswordRoute,
  welcomeRoute,
} from "features/Login/login.routes";
import NextRedirect from "features/Next/NextRedirect";
import PostSignUp from "features/PostSignUp/PostSignUp";
import React, { Suspense, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import WelcomePageView from "features/Welcome/WelcomePageView";
import LoginPageView from "features/Login/LoginPage/LoginPageView";
import RegistrationView from "features/Registration/RegistrationView";
import useCloseLogin from "features/Closed/hooks/useCloseLogin";
import RequestRestorePageView from "features/RequestRestorePassword";
import RestorePasswordView from "features/RestorePassword";

const PublicContent = () => {
  const { closed, isLoading } = useCloseLogin();


  return (
    <Suspense fallback={null}>
      {!isLoading && (
        <RootLayout>
          {!closed && (
            <Switch>
              <Route
                path={artworkRouteConfig.path}
                component={PublicArtworkView}
              />
              <Route
                path={restorePasswordRoute.path}
                component={RestorePasswordView}
              />
              <Route path="/in-app" exact component={PostSignUp} />
              <Route
                path={welcomeRoute.path}
                exact
                component={WelcomePageView}
              />
              <Route path={loginRoute.path} exact component={LoginPageView} />
              <Route
                path={requestRestoreLink.path}
                exact
                component={RequestRestorePageView}
              />

              <Route
                path={registrationRoute.path}
                exact
                component={RegistrationView}
              />
              <Redirect to={welcomeRoute.path} />
            </Switch>
          )}
          {closed && (
            <Switch>
              <Route path="/developer-login" exact component={LoginPageView} />
              <Route path="/" exact component={ClosedView} />
              <Redirect to="/" />
            </Switch>
          )}
        </RootLayout>
      )}
    </Suspense>
  );
};

export default PublicContent;
