import { api } from "index";
import { useQuery, UseQueryOptions } from "react-query";
import { ProfileItem } from "../interfaces/profile.interfaces";

export const SELF_INFO_CACHE_KEY = "self-info";

const fetchSelfInfoRequest = () => {
  return api
    .get(`/api/v1/user/whoami`)
    .json<{ user: ProfileItem }>()
    .then(({ user }) => user);
};
export default function useSelfInfo(config?: UseQueryOptions<ProfileItem>) {
  return useQuery([SELF_INFO_CACHE_KEY], fetchSelfInfoRequest, {
    enabled: false,
    keepPreviousData: true,
    ...config
  });
}
