import useMobileMenu from "features/MobileMenu/hooks/useMobileMenu";
import useShallowSearch from "features/Search/hooks/useShallowSearch";
import React, { useCallback } from "react";
import { HeaderActionProps } from "../header.interfaces";
import MenuButton from "./MenuButton";

const MenuAction = ({ onClick, ...rest }: HeaderActionProps) => {
  const { opened, close, open } = useMobileMenu();
  const { hideSearch } = useShallowSearch();

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      if (!opened) {
        open();
        hideSearch();
        
      } else {
        close();
      }
      onClick && onClick(e);
    },
    [close, hideSearch, onClick, open, opened]
  );


  return <MenuButton active={opened} onClick={handleClick} {...rest} />;
};

export default MenuAction;
