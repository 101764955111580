import clsx from "clsx";
import { useNestedMenu } from "components/NestedMenu/NestedMenu";
import React from "react";

interface FilterMenuButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  name: string;
  active?: boolean;
}

const FilterMenuButton = ({
  onClick,
  name,
  className,
  active,
  ...props
}: FilterMenuButtonProps) => {
  const { expand, expanded } = useNestedMenu();

  const current = active || expanded[name];

  return (
    <button
      type="button"
      className={clsx(className, { active: current })}
      onMouseEnter={() => {
        // if (active) return
        expand(name)
      }}
      onClick={(e) => {
        expand(name);
        onClick && onClick(e);
      }}
      {...props}
    />
  );
};

export default FilterMenuButton;
