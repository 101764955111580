import { api } from "index";
import { useMemo } from "react";
import { useQuery, UseQueryOptions } from "react-query";
import { VIEWING_ROOM_CACHE_KEY } from "../api.viewingroom";
import { ViewingRoom } from "../interfaces/viewing-room.interfaces";

export const sponsorsOrder: Record<string, number> = {
  804: 0,
};

export const sponsorsSorter = (a: ViewingRoom, b: ViewingRoom) => {
  return (sponsorsOrder[a.id] ?? 1) - (sponsorsOrder[b.id] ?? 1);
};

const fetchSponsorsRequest = () => {
  return api
    .get(`/api/v1/user/sponsors`)
    .json<{ viewingRooms: ViewingRoom[] }>()
    .then(({ viewingRooms }) => viewingRooms);
};

export default function useSponsors(config?: UseQueryOptions<ViewingRoom[]>) {
  const { data, ...rest } = useQuery(
    [VIEWING_ROOM_CACHE_KEY, "sponsors"],
    fetchSponsorsRequest,
    { staleTime: Infinity, ...config }
  );

  const computedData = useMemo(() => {
    if (!data) {
      return data;
    }
    return data.sort((a, b) => {
      return sponsorsOrder[a.id] - sponsorsOrder[b.id];
    });
  }, [data]);

  return { data: computedData, ...rest };
}
