import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import { homeRouteConfig } from "features/Home/home.routes";
import { WithClassNameProps } from "interfaces";
import React from "react";
import { Link } from "react-router-dom";
import { zIndex } from "styles";
import logo from "images/logo-expo.png";
import SvgNorthernTrust from "components/icons/NorthernTrust";

import { up } from "styles/utils/breakpoints";

export interface BaseHeaderProps {
  Controls: React.ComponentType<any>;
  className?: string;
  Decoration?: React.ComponentType<WithClassNameProps>;
  children?: React.ReactNode;
}

const headerSecondaryItemCss = css`
  display: flex;
  /* flex: 1 0 20%; */
  &:last-child {
    justify-content: flex-end;
  }
`;

export const HeaderPlaceholder = styled.header`
  height: 84px;
  height: var(--header-height, 84px);
`;

const Root = styled.header`
  position: relative;
  z-index: ${zIndex.header};
  display: flex;
  align-items: center;

  height: 84px;
  height: var(--header-height, 84px);
  border-bottom: solid 1px #000;

  background-color: #fff;

  /* padding-inline: 120px; */
  padding: 0 120px;

  ${up("xl")} {
    /* padding-inline: 220px; */
    padding: 0 120px;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  flex-grow: 1;
  flex-shrink: 1;
`;

const HomeLink = styled(Link)`
  display: flex;
`;

const LogoContainer = styled.div`
  height: 45px;
  & img {
    height: 100%;
  }
`;

const List = styled.ul`
  display: flex;
  align-items: center;
  height: 100%;
`;

const Logos = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

const DefaultDecoration = ({ className }: WithClassNameProps) => {
  return <div className={className}></div>;
};

const BaseHeader = ({
  children,
  Controls,
  className,
  Decoration = DefaultDecoration,
}: BaseHeaderProps) => {
  return (
    <Root className={className}>
      <Content>
        <Logos>
          <HomeLink to={homeRouteConfig.link}>
            <LogoContainer>
              <img src={logo} alt="" />
            </LogoContainer>
          </HomeLink>

          <div>
            <SvgNorthernTrust color="#000" height="49px" />
          </div>
        </Logos>
        <List>{!!children && children}</List>
        <div className={headerSecondaryItemCss}>
          <Controls />
        </div>
      </Content>
      <Decoration className={headerSecondaryItemCss} />
    </Root>
  );
};

export default BaseHeader;
