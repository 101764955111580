import * as React from "react";

const SvgArrowThin = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    className="outline"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 13 10"
    {...props}
  >
    <path d="M1 1l5.546 8L12 1" stroke="#fff" />
  </svg>
);

export default SvgArrowThin;
