import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { scrollToTop } from "utils/scrollToTop";
import { restoreScrollPosition } from "../ScrollStore";

export default function useScrollObserver() {
  const location = useLocation();

  useEffect(() => {
    if (location.key) {
      restoreScrollPosition(location.key);
    } else {
      scrollToTop();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
}
