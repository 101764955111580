import { DefaultOptions } from "react-query";
import isHTTPError from "./utils/isHttpError";

type onUnauthHandler = () => void;

export const createQueryConfig = (
  handler: onUnauthHandler
): DefaultOptions => ({
  queries: {
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    onError(err) {
      if (
        isHTTPError(err) &&
        (err.response.status === 401 || err.response.status === 403)
      ) {
        handler();
      }
    },
  },
});
