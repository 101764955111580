import { styled } from "@linaria/react";
import clsx from "clsx";
import "components/Animations/Fade";
import SearchOverlay from "components/SearchOverlay/SearchOverlay";
import useMobileMenu from "features/MobileMenu/hooks/useMobileMenu";
import useShallowSearch from "features/Search/hooks/useShallowSearch";
import { searchRoute } from "features/Search/search.routes";
import useHandleKey from "hooks/useHandleKey";
import React, { useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import em from "styles/utils/em";
import SubheaderBase from "../SubheaderBase/SubheaderBase";

const Input = styled.input`
  height: 100%;
  width: 100%;
  max-height: 40px;
  border: 0;
  padding: 0 20px;
  font-size: 16px;
  background-color: #00000020;
  border-radius: 50px;
  letter-spacing: ${em("0.16px", "14px")};
  &::placeholder {
    font-size: 16px;
    text-transform: initial;
    color: #000;
  }
  margin-inline: 30px;
`;

const Root = styled.div``;

const OverlayWrapper = styled.div`
  position: absolute;
  top: 75px;
  overflow: auto;
  left: 0;
  right: 0;
  transition: opacity 200ms ease-in-out;
  transition-property: background-color, opacity;

  &.overlay,
  &.results {
    height: calc(100vh - var(--header-height));
  }
  &.overlay {
    background-color: rgba(0, 0, 0, 0.3);
  }
  &.results {
    background-color: #ffffff;
  }
`;

const SearchOverlayMobile = () => {
  const {
    handleSearchChange,
    searchOverlayState: { active, searchString, showResults },
    deepSearch,
    handleClearSearch,
  } = useShallowSearch();
  const { close, opened } = useMobileMenu();

  const match = useRouteMatch({ path: searchRoute.path, exact: false });

  const handleEnter = useHandleKey("Enter", () => {
    deepSearch();
    close();
  });

  useEffect(() => {
    if (!opened) handleClearSearch();
  }, [opened]);

  return (
    <Root>
      <SubheaderBase>
        <Input
          value={searchString}
          onKeyDown={handleEnter}
          autoFocus
          onChange={handleSearchChange}
          placeholder="Search..."
        />
      </SubheaderBase>

      <CSSTransition
        mountOnEnter
        unmountOnExit
        classNames="fade-in"
        in={active || !!match}
        timeout={200}
      >
        <OverlayWrapper
          className={clsx({
            overlay: !match,
            results: showResults,
          })}
        >
          {showResults && <SearchOverlay />}
        </OverlayWrapper>
      </CSSTransition>
    </Root>
  );
};

export default SearchOverlayMobile;
