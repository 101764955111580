import { ArtworkItem } from "features/Artwork/artwork.interfaces";
import { api } from "index";
import { QueryFunctionContext, useQuery, UseQueryOptions } from "react-query";
import { ARTWORK_CACHE_KEY } from "..";
import useGetCurrentArtId from "./useGetCurrentArtId";

const fetchArtworkRequest = async (
  context: QueryFunctionContext<[string, string]>
) => {
  const [, id] = context.queryKey;
  const response = await api
    .get(`/api/v1/user/artworks/${id}`)
    .json<{ item: ArtworkItem }>();
  return response.item;
};

const fetchPublicArtworkRequest = async (
  context: QueryFunctionContext<[string, string]>
) => {
  const [, id] = context.queryKey;
  const response = await api
    .get(`/api/v1/public/artworks/${id}`)
    .json<{ item: ArtworkItem }>();
  return response.item;
};

export default function useArtwork(
  id?: string | number,
  config?: UseQueryOptions<ArtworkItem>
) {
  const currentArtId = useGetCurrentArtId();

  id = id || currentArtId;

  return useQuery([ARTWORK_CACHE_KEY, id.toString()], fetchArtworkRequest, {
    enabled: typeof config?.enabled !== "undefined" ? config?.enabled : !!id,
    staleTime: 15 * 60 * 1000,
    ...config,
  });
}

export function usePublicArtwork(
  id?: string | number,
  config?: UseQueryOptions<ArtworkItem>
) {
  const currentArtId = useGetCurrentArtId();

  id = id || currentArtId;

  return useQuery(
    [`${ARTWORK_CACHE_KEY}-public`, id.toString()],
    fetchPublicArtworkRequest,
    {
      enabled: typeof config?.enabled !== "undefined" ? config?.enabled : !!id,
      staleTime: 15 * 60 * 1000,
      ...config,
    }
  );
}
