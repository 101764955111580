import * as React from "react";

const SvgPlay = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    className="filled"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 45 46"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5 0C9.178 0 0 9.178 0 20.5v5C0 36.822 9.178 46 20.5 46h4C35.822 46 45 36.822 45 25.5v-5C45 9.178 35.822 0 24.5 0h-4zM19 31l12-7-12-7v14z"
      fill="#fff"
    />
  </svg>
);

export default SvgPlay;
