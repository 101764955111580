import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import { loginAsAdmin, logoutAsAdmin } from "../store/authSlice";

export default function usePreviewSession() {
  const isPreview = useSelector((root: RootState) => root.auth.isPreview);
  const dispatch = useDispatch();

  const loginAdmin = useCallback(
    (token: string) => {
      dispatch(loginAsAdmin(token));
    },
    [dispatch]
  );

  const logoutAdmin = useCallback(() => {
    dispatch(logoutAsAdmin());
  }, [dispatch]);

  return { loginAdmin, logoutAdmin, isPreview };
}
