import * as React from "react";

const SvgArrowTiny = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    className="outlined"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 7 11"
    stroke="currentColor"
    {...props}
  >
    <path d="M1 10l5-4.5L1 1" />
  </svg>
);

export default SvgArrowTiny;
