import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type ContextNavigationMode = "search" | "saved" | null;

// Navigation Mode Types
export type SectionContextNavigationMode = ContextNavigationMode | "section";
export type ViewingRoomContextNavigationMode = ContextNavigationMode | "gallery" | "section";
export type ArtworkContextNavigationMode = ContextNavigationMode | "artwork" | "viewing-room";
export type FairContextNavigationMode = ContextNavigationMode | "fair";

// Navigation States Types
type SectionContextNavigation = {
  mode: SectionContextNavigationMode;
  id: null | number;
  ids: Array<number>;
  title: string;
}

type ViewingRoomContextNavigation = {
  mode: ViewingRoomContextNavigationMode;
  id: null | number;
  ids: Array<number>;
  title: string;
}

type ArtworkContextNavigation = {
  mode: ArtworkContextNavigationMode;
  id: null | number;
  ids: Array<number>;
  title: string;
}

type FairContextNavigation = {
  mode: FairContextNavigationMode;
  id: null | number;
  ids: Array<number>;
  title: string;
}

// State Type
type State = {
  fair: FairContextNavigation;
  section: SectionContextNavigation;
  viewingRoom: ViewingRoomContextNavigation;
  artwork: ArtworkContextNavigation;
};

const initialState: State = {
  fair: {
    mode: null,
    id: null,
    ids: [],
    title: "",
  },
  section: {
    mode: null,
    id: null,
    ids: [],
    title: "",
  },
  viewingRoom: {
    mode: null,
    id: null,
    ids: [],
    title: "",
  },
  artwork: {
    mode: null,
    id: null,
    ids: [],
    title: "",
  },
};

const { reducer, actions } = createSlice({
  name: "contextNavigation",
  initialState,
  reducers: {
    setSectionMode(state, action: PayloadAction<{ mode: SectionContextNavigationMode }>) {
      state.section.mode = action.payload.mode;
    },
    setViewingRoomMode(state, action: PayloadAction<{ mode: ViewingRoomContextNavigationMode }>) {
      state.viewingRoom.mode = action.payload.mode;
    },
    setArtworkMode(state, action: PayloadAction<{ mode: ArtworkContextNavigationMode }>) {
      state.artwork.mode = action.payload.mode;
    },
    setFairMode(state, action: PayloadAction<{ mode: FairContextNavigationMode}>) {
      state.fair.mode = action.payload.mode;
    },
    setNavigation(
      state,
      action: PayloadAction<{
        ids: Array<number>;
        id?: number;
        title: string;
        section: "viewingRoom" | "artwork" | "section" | "fair";
      }>
    ) {
      const { ids, section, title, id } = action.payload;
      if (id) state[section].id = id || null;
      state[section].ids = ids;
      state[section].title = title;
    },
  },
});

export default reducer;

export const { setArtworkMode, setViewingRoomMode, setSectionMode, setNavigation } = actions;
