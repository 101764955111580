import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { logIn } from "features/Auth/store/authSlice";
import ky from "ky";
import { AppThunk } from "store";

const signInEndpoint = "/api/v1/public/auth";

export type SignInFormType = {
  email: string;
  password: string;
};

type SignInStep = "form" | "code";

const initialState: {
  formData?: SignInFormType;
  step: SignInStep;
} = {
  step: "form",
};

const { reducer, actions } = createSlice({
  name: "signIn",
  initialState,
  reducers: {
    setFormData(state, action: PayloadAction<{ formData: SignInFormType }>) {
      state.formData = action.payload.formData;
    },
    setCodeStep(state) {
      state.step = "code";
    },
    clearSignIn() {
      return initialState;
    },
  },
});

export default reducer;

export const { setFormData, clearSignIn, setCodeStep } = actions;

export interface SignInFormWithForm {
  form: SignInFormType
}

export const signIn = (form: SignInFormWithForm): AppThunk => async (
  dispatch,
) => {
  try {
    const request = await ky.post(signInEndpoint, {
      method: "POST",
      json: form,
    })
    const { token } = await request.json();
    console.log(token)

    dispatch(logIn(token));
    dispatch(clearSignIn());

  } catch (error) {
  }
};
