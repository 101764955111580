import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type MainState = {
  isDarkTheme: boolean;
};

const initialState: MainState = {
  isDarkTheme: false,
};

const { reducer, actions } = createSlice({
  name: "main",
  initialState,
  reducers: {
    setDarkTheme(state, action: PayloadAction<{ isDarkTheme: boolean }>) {
      state.isDarkTheme = action.payload.isDarkTheme;
    },
  },
});

export const { setDarkTheme } = actions;

export default reducer;
