import * as React from "react";

const SvgChatGreenIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1920 1920"
    {...props}
  >
    <path
      d="M537.8 535.8q352.1.5 704.1.1a80.4 80.4 0 0118.3 2.2c66.7 15.3 111.4 71.5 111.3 141.1q-.1 168 0 336 0 94.5.2 189.2c0 25.1.1 50.3-.1 75.4-.5 77.4-61.9 139.2-139.3 139.6q-238.2 1.5-476.5 2c-8 0-13.3 2.5-18.6 8.3q-133.8 147.9-268.1 295.4c-2.5 2.7-4.4 6.2-8.8 7.7-1.9-3.5-.9-7.3-.9-10.9q-.1-143.6.1-287.3c.1-10.1-1.8-13.4-12.7-13.3-60.3.6-120.7-1.2-180.9.7-75.5 2.4-138.8-51.2-147.9-124.8v-17.5l.2-614.5a29.6 29.6 0 00-.3-3.1c6.5-56.3 47.1-107.7 109-121.9a170.2 170.2 0 0137-3.7l241.9-.4m466.7 454.5c-.2 47.5 38.5 86.6 85.8 86.4s85.4-38.8 85.5-85.8a85.7 85.7 0 00-171.3-.6zm-228.2-84.8a85.3 85.3 0 00-85.5 86.3c.5 46.4 39.4 85.1 85.4 84.9a85.6 85.6 0 0085.7-86.3c-.2-47.6-37.9-85.1-85.6-84.9zm-313.9 0c-47.5.1-85 37.9-84.9 85.6a85.6 85.6 0 0085.4 85.6c46.4.3 85.6-38.8 85.8-85.6s-38.5-85.8-86.3-85.6z"
      fill="#03ab8b"
    />
    <path
      d="M517.1 536c0-59.5-.2-119.3 0-178.8.2-64.1 49-121.9 112.1-133.6l10.1-2h692.9c60.3.2 120.7.2 181 .5 41 .2 82 .5 123 1.3 72.3 1.3 134.3 63.6 134.4 135.9q.5 306 0 612.2c-.1 72.1-62.4 134.5-134.5 135.7-17.9.4-35.9.6-53.8-.1-8.9-.3-10.8 2.9-10.7 11.3q.3 144.1.1 288.5v12.3c-6.1-1.8-8.6-6.1-11.6-9.4q-60.6-66.5-120.9-133.1c-19.7-21.6-39.3-43.4-59-65-2.5-2.8-4.7-6-8.5-7.3q-.2-94.7-.2-189.2-.1-168 0-336c.1-69.6-44.6-125.8-111.3-141.1a80.4 80.4 0 00-18.3-2.2"
      fill="#91d1c5"
    />
  </svg>
);

export default SvgChatGreenIcon;
