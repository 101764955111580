import useScrollObserver from "./hooks/useScrollObserver";

export type ScrollConfigRecord = { section: string; activeRoutes: string[] };

const ScrollController = () => {
  useScrollObserver();
  return null;
};

export default ScrollController;
