import React, { Suspense } from "react";
import { up } from "styles/utils/breakpoints";
import useBreakpoint from "styles/utils/useBreakpoint";
const Chat = React.lazy(() => import("./components/ChatComponent"));
const ChatMobile = React.lazy(() => import("./components/ChatMobile"));

const ChatView = () => {
  const upMd = useBreakpoint(up("md"));

  return (
    <Suspense fallback={null}>{upMd ? <Chat /> : <ChatMobile />}</Suspense>
  );
};

export default ChatView;
