import { styled } from "@linaria/react";
import clsx from "clsx";
import React, { PropsWithChildren } from "react";
import { DARK_CLASS } from "styles";
import RootLayout from "./Layout/RootLayout/RootLayout";

type RootThemedLayoutProps = PropsWithChildren<{
  className?: string;
}>;

const Root = styled(RootLayout)`
  &.${DARK_CLASS} {
    color: white;
    background-color: #14172F ;
  }
  
`;

const RootThemedLayout = ({ children, className }: RootThemedLayoutProps) => {
  // const { isDarkTheme } = useTheme();

  return <Root className={clsx(className)}>{children}</Root>;
};

export default RootThemedLayout;
