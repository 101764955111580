import * as React from "react";

const SvgBurgerMobile = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    className="outlined"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 22"
    {...props}
  >
    <path
      d="M2.23684 2.47919H48"
      stroke="white"
      stroke-width="3"
      stroke-linecap="square"
    />
    <path
      d="M2.23684 19.7327H48"
      stroke="white"
      stroke-width="3"
      stroke-linecap="square"
    />
    <path
      d="M2.23684 11.106H48"
      stroke="white"
      stroke-width="3"
      stroke-linecap="square"
    />
  </svg>
);

export default SvgBurgerMobile;
