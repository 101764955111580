import * as React from "react";

const SvgZoomPlus = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 22 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.591 10.5H20.41M11 1.091V19.91"
      stroke="currentColor"
      strokeLinecap="square"
    />
  </svg>
);

export default SvgZoomPlus;
